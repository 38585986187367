const isNumber = evt => {
  evt = (evt) ? evt : window.event
  const charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 190) {
    evt.preventDefault()
  } else {
    return true
  }
}

const onlyNumberPattern = /[^A-Za-zА-Яа-я]+/g

const trimOnlyNumbers = value => {
  return value.replace(onlyNumberPattern, '')
}

export { isNumber, trimOnlyNumbers, onlyNumberPattern }
