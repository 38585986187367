
import {
  ref,
  watch,
  defineComponent
} from 'vue'
import { ElMessage } from 'element-plus'
import { validationRules as rules } from '@/core/helpers/validation/validationRules'

export default defineComponent({
  name: 'BaseUpload',
  props: {
    modelValue: {
      default: () => {
        return []
      },
      type: Array,
      required: false
    },
  },
  setup(props, context) {

    const photosList = ref([])


    const checkFileSize = size => {
      const isLt10Mb = size <= rules.upload.common.fileSize.max.value
      if (isLt10Mb) {
        return true
      } else {
        ElMessage.error(`Размер файла не должен быть более ${rules.upload.common.fileSize.max.label}`)
        return false
      }
    }


    const typeStatus = type => {
      const typeStatus = type === 'image/png' || type === 'image/jpeg'
      if (typeStatus) {
        return true
      } else {
        ElMessage.error('Разрешены только PNG или JPG форматы')
        return false
      }
    }


    const validateFile = file => {
      let result = false
      const fileSize = file.size
      const fileType = file.raw.type

      if (checkFileSize(fileSize) && typeStatus(fileType)) {
        result = true
      } else {
        result = false
      }
      return result
    }


    /**
     * This function trimmes and saves the array with 5 first elements.
     * @param {array} filelist array of uploaded images.
     * @returns {array} trimmed array: from 0 to 5 items.
     */
    const removeExtraItems = array => {
      ElMessage.warning(`максимум ${rules.upload.common.filesLength.max.value} фото `)
      const trimmedArray = array.slice(0, rules.upload.common.filesLength.max.value)
      return trimmedArray
    }


    /**
     * Emitting the value to the parent.
     * @param {object} file file object
     */
    const emit = data => {
      context.emit('update:modelValue', data)
    }


    /**
     * This function make the validation for the Upload field.
     * Max image length: 5;
     * Types: PNG, JPG;
     * Max image size: 10Mb;
     * @param {object} file object
     * @param {array} filelist images array
     */
    const handleChangeUpload = (file, filelist) => {
      /*
        Make a copy.
      */
      const photoInit = []
      let photosCopy = filelist

      /*
        Max count validation:
        - not more than 5 items.
      */
      console.log('> photosCopy.length', photosCopy.length)
      if (photosCopy.length > 5) {
        photosCopy = removeExtraItems(photosCopy)
      }

      photosCopy.forEach(item => {
        /*
          One more validation:
          - image type checking;
          - image size checking.
        */
        if (item.raw) {
          if (validateFile(item)) {
            /*
              If validation is OK,
              then we should mark the file as a NEW one
              for the server uploading.
            */
            item.new = true
            item.id = item.uid
            photoInit.push(item)
          }
        } else {
          photoInit.push(item)
        }
      })
      console.log('> photoInit', photoInit)

      /*
        After all we need to update the input array and the form array.
      */
      photosList.value = photoInit
    }


    /**
     * Handle removing files.
     * @param {object} File object
     */
    const handleRemoveUpload = file => {
      photosList.value = photosList.value.filter(
        item => item.id !== file.id
      )
    }


    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)

    const handlePreviewUpload = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!
      dialogVisible.value = true
    }


    /**
     * Watching for the Photos list value changes.
     */
    watch(
      () => photosList.value,
      newValue => {
        emit(newValue)
      }
    )


    /**
     * Watching for the Photos list property value changes,
     * and making a copy of the props photo list.
     */
    watch(
      () => props.modelValue,
      newValue => {
        photosList.value = newValue
      }
    )


    return {
      rules,
      photosList,
      handleChangeUpload,
      handleRemoveUpload,
      dialogVisible,
      dialogImageUrl,
      handlePreviewUpload,
    }
  },
});
