
import {
  ref,
  watch,
  computed,
  onMounted,
  onBeforeUpdate,
  defineComponent
} from 'vue'
import store from '@/store/index'
import { Actions, Mutations } from '@/store/enums/store.enums'
import BasePre from '@/core/components/base/BasePre.vue'
import isEmptyObject from '@/core/helpers/isEmptyObject'
import { isNumber } from '@/core/helpers/isNumber'
import { validationRules } from '@/core/helpers/validation/validationRules'
import ApiService from '@/core/services/ApiService'
import DadataService from '@/core/services/DadataService'
import BaseUpload from '@/core/components/base/BaseUpload.vue'
import { ElementAnimateUtil } from '@/assets/ts/_utils/ElementAnimateUtil'
import InterfaceEntryAdsData from '@/assets/ts/_utils/models/InterfaceEntryAdsData'
import { ElMessage } from 'element-plus'



export default defineComponent({
  name: 'FormPage',
  components: {
    BasePre,
    BaseUpload,
  },
  props: {
    data: {
      default: () => {
        return {}
      },
      type: Object,
      required: true
    },
    edit: {
      default: true,
      type: Boolean,
      required: true
    }
  },
  setup(props, context) {

    /**
     * Ref variables.
     */
    const refsType = ref(null)
    const refsName = ref(null)
    const refsDescription = ref(null)
    const refsAddress = ref(null)
    const refsCountry = ref(null)
    const refsUpload = ref(null)
    const refsOrderVolume = ref(null)
    const refsOrderQuantity = ref(null)
    const refsOrderDelivery = ref(null)
    const refsOrderDeliveryAddress = ref(null)
    const refsOrderPaymentMethod = ref(null)
    const refsPrice = ref(null)

    const refsArray = ref(null)

    const propEdit = ref(props.edit)




    onBeforeUpdate(() => {
      refsArray.value = []
    })


    /*********************
    * FORM START
    *********************/

    const formRef = ref(null)

    /**
     * The entry data object.
     */
    const entryData = ref({
      id: null,
      active: null,
      status: null,
      type: null,
      code: null,
      fixed: null,
      'fixed_until': null,
      'refine_order': null,
      'rubric_id': null,
      'measure_id': null,
      'only_pro': null,
      name: null,
      price: null,
      description: null,
      photos: [],
      address: null,
      addressString: null,
      'country_of_origin': null,
      'rubric_props': [],
      order: {
        'order_type': null,
        'order_quantity': null,
        'order_delivery_type': null,
        'order_delivery_address': null,
        'order_payment_method': null,
      },
    } as InterfaceEntryAdsData)




    /*********************
    * NEW FORM START
    *********************/

    const disabledDateFromFuture = (time: Date) => {
      return time.getTime() > Date.now()
    }

    const disabledDateFromPast = (time: Date) => {
      return time.getTime() < Date.now()
    }

    const placeholder = 'Введите значение'

    const entryDataNew = ref({
      type: {
        as: 'radio',
        slug: 'type',
        value: null,
        label: 'Тип объявления',
        rules: [
          'required',
        ],
        options: [
          {
            label: 'Предложение',
            value: 'offer',
          },
          {
            label: 'Спрос',
            value: 'demand',
          },
        ],
        available: true,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      name: {
        as: 'text',
        slug: 'name',
        value: null,
        label: 'Заголовок',
        placeholder: placeholder,
        rules: [
          'required',
        ],
        available: true,
        maxlength: 100,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      description: {
        as: 'textarea',
        slug: 'description',
        value: null,
        label: 'Текст объявления',
        placeholder: placeholder,
        rules: [
          'required',
        ],
        available: true,
        maxlength: 1000,
        valid: true,
        message: null,
        color: false,
        toTop: true,
      },
      country: {
        as: 'autocomplete',
        slug: 'country',
        value: null,
        label: 'Страна происхождения товара',
        placeholder: placeholder,
        rules: null,
        available: true,
        maxlength: 50,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      address: {
        as: 'autocomplete',
        slug: 'address',
        value: null,
        json: null,
        id: null,
        label: 'Местонахождение товара',
        placeholder: placeholder,
        rules: [
          'required',
        ],
        available: true,
        maxlength: 100,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      rubric: {
        as: 'cascader',
        slug: 'rubric',
        value: null,
        label: 'Рубрика',
        placeholder: placeholder,
        rules: [
          'required',
        ],
        available: true,
        maxlength: 100,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      id: {
        as: 'text',
        slug: 'id',
        value: null,
        label: 'ID пользователя',
        placeholder: placeholder,
        rules: props.edit ? [] : ['required'],
        maxlength: 10,
        available: true,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      props: [],
      fixed: {
        as: 'date',
        slug: 'fixed',
        value: null,
        label: 'Закреплено до даты',
        placeholder: placeholder,
        maxlength: 10,
        rules: null,
        available: true,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      photos: {
        as: 'upload',
        slug: 'photos',
        value: null,
        label: 'Фотографии',
        placeholder: placeholder,
        available: true,
        rules: null,
        valid: true,
        message: null,
        color: false,
        toTop: true,
      },
      price: {
        as: 'text',
        slug: 'price',
        value: null,
        label: 'Цена',
        placeholder: placeholder,
        rules: [
          'required',
        ],
        available: true,
        maxlength: 9,
        max: 10000000,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      measure: {
        as: 'select',
        slug: 'measure',
        value: null,
        label: 'Цена',
        placeholder: placeholder,
        rules: [
          'required',
        ],
        available: true,
        maxlength: 10,
        valid: true,
        message: null,
        color: false,
        toTop: false,
      },
      pro: {
        as: 'checkbox',
        slug: 'pro',
        value: null,
        label: 'Только PRO',
        placeholder: placeholder,
        rules: null,
        valid: true,
        message: null,
        color: false,
        toTop: true,
      },
      refine: {
        as: 'radio',
        slug: 'refine',
        value: null,
        label: 'Уточнить заказ',
        options: [
          {
            label: 'Да',
            value: true,
          },
          {
            label: 'Нет',
            value: false,
          },
        ],
        rules: null,
        valid: true,
        message: null,
        color: false,
      },
      'order_type': {
        as: 'radio',
        slug: 'order_type',
        value: null,
        label: 'Тип заказа',
        options: [
          {
            label: 'Розница',
            value: 'розница',
          },
          {
            label: 'Опт',
            value: 'опт',
          },
        ],
        available: false,
        rules: [
          'required',
        ],
        valid: true,
        message: null,
        color: true,
        toTop: false,
      },
      'order_quantity': {
        as: 'orderQuantity',
        slug: 'order_quantity',
        value: null,
        label: 'Объем заказа',
        available: false,
        rules: [
          'required',
        ],
        max: 1000000000,
        valid: true,
        message: null,
        color: true,
        toTop: false,
      },
      'order_delivery_type': {
        as: 'radio',
        slug: 'order_delivery_type',
        value: null,
        label: 'Доставка',
        options: [
          {
            label: 'Доставка',
            value: 'Доставка',
          },
          {
            label: 'Самовывоз',
            value: 'Самовывоз',
          },
          {
            label: 'Не требуется',
            value: 'Не требуется',
          },
        ],
        available: false,
        rules: [
          'required',
        ],
        valid: true,
        message: null,
        color: true,
        toTop: false,
      },
      'order_delivery_address': {
        as: 'autocomplete',
        slug: 'order_delivery_address',
        value: null,
        label: 'Адрес доставки',
        placeholder: placeholder,
        available: false,
        maxlength: 100,
        rules: [
          'required',
        ],
        valid: true,
        message: null,
        color: true,
        toTop: false,
      },
      'order_payment_method': {
        as: 'radio',
        slug: 'order_payment_method',
        value: null,
        label: 'Способ оплаты',
        options: [
          {
            label: 'Банк.перевод',
            value: 'Банк.перевод',
          },
          {
            label: 'Наличные',
            value: 'Наличные',
          },
          {
            label: 'Не важно',
            value: 'Не важно',
          },
        ],
        available: false,
        rules: [
          'required',
        ],
        valid: true,
        message: null,
        color: true,
        toTop: false,
      },
      active: null,
      status: null,
      code: null,
      'refine_order': null,
      order: {
        'order_type': null,
        'order_quantity': null,
        'order_delivery_type': null,
        'order_delivery_address': null,
        'order_payment_method': null,
      },
    })

    watch(
      () => entryDataNew.value.refine.value,
      async newValue => {
        if (newValue) {
          entryDataNew.value['order_type'].available = true
          entryDataNew.value['order_quantity'].available = true
          entryDataNew.value['order_delivery_type'].available = true
          entryDataNew.value['order_payment_method'].available = true
        } else {
          entryDataNew.value['order_type'].available = false
          entryDataNew.value['order_quantity'].available = false
          entryDataNew.value['order_delivery_type'].available = false
          entryDataNew.value['order_payment_method'].available = false
        }
      }
    )

    watch(
      () => entryDataNew.value['order_delivery_type'].value,
      async newValue => {
        if (newValue === 'Доставка') {
          entryDataNew.value['order_delivery_address'].available = true
        } else {
          entryDataNew.value['order_delivery_address'].available = false
        }
      }
    )

    const onlyNumberPattern = /[^\d.]/g
    const onlyNumberPattern2 = /\D/g
    const onlyNumberPattern3 = /\D/g

    const isRequired = (index, name) => {
      // console.log('> isRequired index 1', index)
      // console.log('> isRequired name 2', name)
      // console.log('> index ? null', index === null)
      if (index === null) {
        if (
          entryDataNew.value[name].value &&
          (
            entryDataNew.value[name].value.length ||
            entryDataNew.value[name].value > 0
          )
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (
          entryDataNew.value['rubric_props'][index].value &&
          (
            entryDataNew.value['rubric_props'][index].value.length ||
            entryDataNew.value['rubric_props'][index].value > 0
          )
        ) {
          return true
        } else {
          return false
        }
      }
    }

    const onlyNumbers = (index, name) => {
      // console.log('> name', entryDataNew.value[name].value)
      if (index === null) {
        return /^\d+$/.test(entryDataNew.value[name].value)
      } else {
        return /^\d+$/.test(entryDataNew.value['rubric_props'][index].value)
      }
    }

    const checkLastSymbol = name => {
      const valueString = entryDataNew.value[name].value
      const lastSymbol = valueString.slice(-1)
      if (lastSymbol === '.') {
        entryDataNew.value[name]['value'] = valueString.substring(0, valueString.length - 1)
      }
    }

    const trimMultipleDots = name => {
      const valueString = entryDataNew.value[name].value
      const dotsCount = valueString.match(/\./g) ? valueString.match(/\./g).length : 0
      // console.log('> dotsCount', dotsCount)
      if (dotsCount > 1) {
        const dotPosition = valueString.indexOf('.')
        entryDataNew.value[name]['value'] = valueString.slice(0, dotPosition) + valueString.slice(dotPosition + 1)
      }
    }

    const trimDotAtTheEnd = name => {
      const valueString = entryDataNew.value[name].value
      const dotPosition = valueString.indexOf('.')
      if (dotPosition + 1 === valueString.length) {
        entryDataNew.value[name]['value'] = valueString.slice(0, -1)
      }
    }

    const trimPriceLength = name => {
      const valueString = entryDataNew.value[name].value
      const dotPosition = valueString.indexOf('.')
      // console.log('> dotPosition', dotPosition)
      if (dotPosition === -1) {
        const splittedString = valueString.split('.')
        if (splittedString[0].length > 7) {
          entryDataNew.value[name]['value'] = valueString.slice(0, -1)
        }
      }
    }

    const trimDecimals = name => {
      const valueString = entryDataNew.value[name].value
      const dotPosition = valueString.length - valueString.indexOf('.')
      // console.log('> dotPosition', dotPosition, valueString.length - 2)
      if (dotPosition > 3) {
        const dotPosition = valueString.indexOf('.')
        entryDataNew.value[name]['value'] = valueString.slice(0, dotPosition) + valueString.slice(dotPosition + 1)
      }
    }

    // const trimPriceFormat = (name, max) => {
    //   const valueString = entryDataNew.value[name].value
    //   const valueNumber = +valueString
    //   const valueRounded = Math.round(valueNumber)
    //   const lastSymbol = valueString.slice(-1)
    //   if (valueRounded > max) {
    //     return max
    //   } else {
    //     return max
    //   }
    //   // return /^\d+$/.test(entryDataNew.value[name].value)
    // }

    const trimOnlyNumbers = (rubric, name) => {
      // console.log('> name', name)
      if (rubric) {
        entryDataNew.value['rubric_props'][name].value = entryDataNew.value['rubric_props'][name].value.replace(onlyNumberPattern2, '')
      } else {
        const valueString = entryDataNew.value[name].value.toString()
        entryDataNew.value[name].value = valueString.replace(onlyNumberPattern, '')
      }
    }


    const trimOnlyNumbers2 = (rubric, name) => {
      // console.log('> name', name)
      if (rubric) {
        entryDataNew.value['rubric_props'][name].value = entryDataNew.value['rubric_props'][name].value.replace(onlyNumberPattern, '')
      } else {
        const valueString = entryDataNew.value[name].value.toString()
        entryDataNew.value[name].value = valueString.replace(onlyNumberPattern, '')
      }
    }


    const trimOnlyNumbers3 = (rubric, name) => {
      // console.log('> name', name)
      if (rubric) {
        entryDataNew.value['rubric_props'][name].value = entryDataNew.value['rubric_props'][name].value.replace(onlyNumberPattern3, '')
      } else {
        const valueString = entryDataNew.value[name].value.toString()
        entryDataNew.value[name].value = valueString.replace(onlyNumberPattern3, '')
      }
    }


    const trimMinMax = (minValue, maxValue, index) => {
      const min = +minValue
      const max = +maxValue
      const value = +entryDataNew.value['rubric_props'][index].value
      if (value && min && value < min) {
        entryDataNew.value['rubric_props'][index].value = min
      }
      if (value && max && value > max) {
        entryDataNew.value['rubric_props'][index].value = max
      }
      if (!value) {
        entryDataNew.value['rubric_props'][index].value = null
      }
    }


    /**
     * Scroll the window to the invalid field.
     */
    const scrollToInvalid = element => {
      // const refsName = `refs${element.charAt(0).toUpperCase()}${element.slice(1)}`
      // const refsName = `refs-${element}`
      // const refsEl = eval(refsName).value.$el
      console.log('> element', element)
      const refsEl = document.getElementById(element)
      console.log('> refsEl', refsEl)
      if (refsEl) {
        ElementAnimateUtil.scrollTo(refsEl, 100, 500)
      }
    }


    const formIsValid = ref(false)


    const validateField = (slug, flag) => {
      // console.log('> validateField >> slug', slug)
      // console.log('> validateField >> rules', entryDataNew.value[slug])
      if (flag) {
        // console.log('> validateField >> value', slug, entryDataNew.value['rubric_props'][slug].value)
        if (entryDataNew.value['rubric_props'][slug].rules && entryDataNew.value['rubric_props'][slug].rules.length) {
          entryDataNew.value['rubric_props'][slug].rules.some(
            ruleName => {

              if (ruleName === 'required') {
                entryDataNew.value['rubric_props'][slug]['valid'] = isRequired(slug, slug)
                entryDataNew.value['rubric_props'][slug]['message'] = entryDataNew.value['rubric_props'][slug]['valid'] ? '' : 'Обязательное поле'
              }

              if (ruleName === 'onlyNumbers') {
                entryDataNew.value['rubric_props'][slug]['valid'] = onlyNumbers(slug, slug) ? true : false
                entryDataNew.value['rubric_props'][slug]['message'] = entryDataNew.value['rubric_props'][slug]['valid'] ? '' : 'Только числа'
              }

            }
          )
        }
      } else {
        // console.log('> validateField >> value', slug, entryDataNew.value[slug].value)
        if (entryDataNew.value[slug].rules && entryDataNew.value[slug].rules.length) {
          entryDataNew.value[slug].rules.some(
            ruleName => {

              if (ruleName === 'required') {
                entryDataNew.value[slug]['valid'] = isRequired(null, slug)
                entryDataNew.value[slug]['message'] = entryDataNew.value[slug]['valid'] ? '' : 'Обязательное поле'
              }

              if (ruleName === 'onlyNumbers') {
                entryDataNew.value[slug]['valid'] = onlyNumbers(null, slug) ? true : false
                entryDataNew.value[slug]['message'] = entryDataNew.value[slug]['valid'] ? '' : 'Только числа'
              }

            }
          )
        }
      }
    }


    const validateForm = (fieldsArray) => {
      const errors = []
      for (const [key, value] of Object.entries(entryDataNew.value)) {
        if (key === 'props') {
          
          if (entryDataNew.value['rubric_props'] && entryDataNew.value['rubric_props'].length) {
            entryDataNew.value['rubric_props'].forEach(
              (propsItem, propsIndex) => {
                if (propsItem['rules'] && propsItem['rules'].length) {
                  propsItem['rules'].some(
                    propRuleName => {
                      // console.log('>p propRuleName', propRuleName)

                      if (propRuleName === 'required') {
                        // console.log('>p required', propRuleName, onlyNumbers(propsIndex, propRuleName))
                        propsItem['valid'] = isRequired(propsIndex, key)
                        propsItem['message'] = propsItem.valid ? '' : 'Обязательное поле'
                        if (!propsItem['valid']) {
                          errors.push(propsItem.slug)
                        }
                        return propsItem['valid'] ? false : true
                      }

                      if (propRuleName === 'onlyNumbers') {
                        // console.log('>p onlyNumbers', propRuleName, onlyNumbers(propsIndex, propRuleName))
                        propsItem['valid'] = onlyNumbers(propsIndex, propRuleName) ? true : false
                        propsItem['message'] = propsItem.valid ? '' : 'Только числа'
                        if (!value['valid']) {
                          errors.push(propsItem.slug)
                        }
                        return value['valid'] ? false : true
                      }

                    }
                  )
                }
              }
            )
          }

        } else {
          if (value && value['available'] && value['rules'] && value['rules'].length) {
            value['rules'].some(
              ruleName => {
                // console.log('>p ruleName', ruleName)

                if (ruleName === 'required') {
                  // console.log('> required __ ', ruleName, isRequired(null, key))
                  value['valid'] = isRequired(null, key)
                  value['message'] = value['valid'] ? '' : 'Обязательное поле'
                  if (!value['valid']) {
                    errors.push(key)
                  }
                  return value['valid'] ? false : true
                }

                if (ruleName === 'onlyNumbers') {
                  // console.log('> onlyNumbers', ruleName, onlyNumbers(null, key))
                  value['valid'] = onlyNumbers(null, key) ? true : false
                  value['message'] = value['valid'] ? '' : 'Только числа'
                  if (!value['valid']) {
                    errors.push(key)
                  }
                  return value['valid'] ? false : true
                }

              }
            )
          }
        }
      }

      if (fieldsArray.length) {
        fieldsArray.forEach(
          itemName => {
            entryDataNew.value[itemName].value = null
            entryDataNew.value[itemName].message = 'Некорректное значение'
            entryDataNew.value[itemName].valid = false
            errors.push(itemName)
          }
        )
      }

      // console.log('> errors.length', errors.length)
      if (errors.length) {
        // console.log('> errors', errors)
        scrollToInvalid(errors[0])
        return false
      } else {
        return true
      }
    }


    const convertDataForSending = () => {
      entryData.value.id = entryDataNew.value.id.value
      entryData.value.type = entryDataNew.value.type.value
      entryData.value.name = entryDataNew.value.name.value
      entryData.value.description = entryDataNew.value.description.value
      entryData.value['country_of_origin'] = entryDataNew.value.country.value
      entryData.value['address_id'] = entryDataNew.value.address.id
      entryData.value['address'] = entryDataNew.value.address.value
      entryData.value['address_json'] = entryDataNew.value.address.json
      entryData.value['rubric_id'] = entryDataNew.value.rubric.value
      entryData.value['fixed_until'] = entryDataNew.value.fixed.value
      entryData.value['refine_order'] = entryDataNew.value.refine.value
      entryData.value['photos'] = entryDataNew.value.photos.value
      entryData.value['price'] = entryDataNew.value.price.value
      entryData.value['only_pro'] = entryDataNew.value.pro.value
      entryData.value['measure_id'] = entryDataNew.value.measure.value
      entryData.value['rubric_props'].forEach(
        (item, index) => {
          item.value = entryDataNew.value['rubric_props'][index].value
        }
      )
      if (entryDataNew.value.refine.value) {
        entryData.value.order['order_type'] = entryDataNew.value['order_type'].value
        entryData.value.order['order_quantity'] = entryDataNew.value['order_quantity'].value
        entryData.value.order['order_delivery_type'] = entryDataNew.value['order_delivery_type'].value
        entryData.value.order['order_delivery_address'] = entryDataNew.value['order_delivery_address'].value
        entryData.value.order['order_payment_method'] = entryDataNew.value['order_payment_method'].value
      }
    }


    const checkPrice = async () => {
      ApiService.setHeader()

      console.log('> entryDataNew.value.price.value', entryDataNew.value.price.value)
      return await ApiService.get(`/pubs/check/price-reliability?type=${entryDataNew.value.type.value}&rubric=${entryDataNew.value.rubric.value}&price=${entryDataNew.value.price.value}`)
        .then(response => {
          if (response.data.isPriceReliable) {
            return true
          } else {
            return false
          }
        })
          .catch(error => {
            console.error('> checkPrice > Error', error)
            return false
          })
    }


    const saveForm = () => {
      if (validateForm([])) {
        if (checkPrice()) {
          convertDataForSending()
          context.emit('saveFormEmit', true)
        } else {
          ElMessage({
            offset: 50,
            type: 'warning',
            message: 'Цена не выглядит достоверной',
          })
        }
      }
    }


    /*********************
    * NEW FORM END
    *********************/


    /**
     * Get the single entry.
     */
    const getEntryOnLoad = () => {
      if (props.data && !isEmptyObject(props.data)) {
        return props.data
      }
    }



    /**
     * Iterates data object and clears to null value properties.
     */
    const clearRubricProps = () => {
      entryData.value['rubric_props'].forEach(
        item => {
          item.value = null
          item['value_enum'] = null
        }
      )
    }

    /**
     * Reset the form.
     */
    const resetForm = () => {
      // console.log('> run ResetForm')
      formRef.value.resetFields()
      entryData.value.photos = []
      clearRubricProps()

      for (const [key, value] of Object.entries(entryData.value)) {
        if (
          !(
            key === 'rubric_props'
            || key === 'id'
            || key === 'code'
            || key === 'type'
            || key === 'order'
            || key === 'photos'
            || key === 'status'
            || key === 'active'
          )
        ) {
          entryData.value[key] = null
        }
      }
    }

    /*********************
    * FORM END
    *********************/



    /*********************
    * ADDRESS START
    *********************/

    const addressString = ref(null)


    /**
     * Request for address suggestions.
     */
    const addressSuggestions = async (queryString, cb) => {
      DadataService.setHeader()

      const query = {
        query: queryString,
        'from_bound': {
          value: 'city'
        },
        'to_bound': {
          value: 'settlement'
        },
        locations: [
          {
            country: '*'
          }
        ],
      }

      const resultSuggestions = await DadataService.post('/suggest/address', { ...query })
        .then(response => {
          console.log('> response', response)
          return response.data.suggestions
        })
          .catch(error => {
            console.error('> addressSuggestions > Error', error)
            return []
          })

        cb(resultSuggestions)
    }

    /**
     * Request for countries suggestions.
     */
    const countrySuggestions = async (queryString, cb) => {
      DadataService.setHeader()

      const resultSuggestions = await DadataService.post('/suggest/country', { query: queryString })
        .then(response => {
          return response.data.suggestions
        })
          .catch(error => {
            console.error('> countrySuggestions > Error', error)
            return []
          })

        cb(resultSuggestions)
    }

    /**
     * Set to null the address field.
     */
    const clearAddress = () => {
      entryData.value.address = null
      entryDataNew.value.address.value = null
      entryDataNew.value.address.json = null
    }


    /**
     * API Request for the address ID.
     * @returns {number}.
     */
    const getAddressId = async addressString => {
      ApiService.setHeader()

      return await ApiService.get(`/addresses/unrestricted?unrestricted_value=${addressString}`)
        .then(response => {
          console.log('> getAddressId', response.data)
          if (response.data['address_id']) {
            return response.data['address_id']
          } else {
            return null
          }
        })
          .catch(error => {
            console.error('> getAddressId > Error', error)
            return null
          })
    }


    /**
     * Convert the selected delivery address to object.
     * @param {object}.
     */
    const selectDeliveryAddress = async data => {
      // console.log('> selectDeliveryAddress item', data)
      entryData.value.order['order_delivery_address'] = data.value
    }


    const changeDeliveryAddress = data => {
      // console.log('> changeDeliveryAddress item', data)
      if (entryData.value.order['order_delivery_address'].length === 0) {
        clearAddress()
      }
    }


    const changeRubric = (slug) => {
      console.log('> changeRubric >> slug', slug)
      console.log('> changeRubric >> data', entryDataNew.value[slug].value)
      console.log('> changeRubric >> isRequired', isRequired(null, slug))
    }


    /**
     * Convert the selected address to object.
     * @param {object}.
     */
    const selectAddress = async (data, name) => {
      console.log('> selectAddress >> data', data)
      // console.log('> selectAddress >> name', name)
      entryDataNew.value[name].value = data.value
      entryDataNew.value[name].json = data
      entryDataNew.value[name].id = null
    }


    const changeAddress = (data) => {
      console.log('> changeAddress')
      if (entryData.value.addressString.length === 0) {
        clearAddress()
      }
      if (!data) {
        console.log('> my clear')
        clearAddress()
      }
    }

    /*********************
    * ADDRESS END
    *********************/



    /*********************
    * MEASURE START
    *********************/

    const measuresList = ref([])

    const priceMeasureId = computed({
      get() {
        return entryDataNew.value.measure.value
      },
      set(value) {
        const measureObject = measuresList.value.filter(item => value === item.id)
        console.log('> measureObject', measureObject[0].name, measureObject[0].id)
        // entryData.value['measure_id'] = measureObject[0].id
        entryDataNew.value.measure.value = measureObject[0].id
      }
    })


    /**
     * Request for measures.
     * @returns {array} An array of measures or an empty array.
     */
    const getMeasures = async () => {
      ApiService.setHeader()

      return await ApiService.get('/pubs/measures')
        .then(response => response.data)
          .catch(error => {
            console.error('> getMeasures > Error', error)
            return []
          })
    }


    /**
     * Measure label detection.
     * @returns {object|null}.
     */
    const detectMeasureLabel = id => {
      let measure = null
      measuresList.value.filter(
        item => {
          if (item.id === id) {
            measure = item
          }
        }
      )
      return measure
    }

    /**
     * Convert the data for editing.
     * @param {object} Entry data object.
     */
    const convertDataForEdit = data => {
      entryData.value.addressString = data.address.value
      entryData.value.photos.forEach(
        item => {
          item.name = item.original_name
          item.url = item.path
        }
      )
      entryData.value['rubric_props'].forEach(
        item => {
          if (item['type_of'] === 'D') {
            const dateArray = item.value.split('.')
            dateArray.reverse()
            const normalDateString = `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`
            const normalDate = new Date(normalDateString).toISOString()
            item.value = normalDate
          }
        }
      )
    }

    

    /**
     * Convert the data for editing.
     * @param {object} Entry data object.
     */
    const convertEntryDataNewForEdit = data => {
      const newData = {
        type: {},
        name: {},
        description: {},
        country: {},
        address: {},
        rubric: {},
        props: [],
        fixed: {},
        photos: {},
        price: {},
        measure: {},
        pro: {},
      }

      entryDataNew.value.type['value'] = data.type
      entryDataNew.value.name['value'] = data.name
      entryDataNew.value.description['value'] = data.description
      entryDataNew.value.country['value'] = data['country_of_origin']

      entryDataNew.value.address.value = data.addressString
      entryDataNew.value.address.json = data.address
      entryDataNew.value.address.id = data.address.id

      entryDataNew.value.rubric['value'] = data['rubric_id']

      entryDataNew.value.fixed['value'] = data['fixed_until']
      entryDataNew.value.photos['value'] = data.photos
      entryDataNew.value.price['value'] = data.price
      entryDataNew.value.measure['value'] = data['measure_id']
      entryDataNew.value.pro['value'] = data['only_pro']

      entryDataNew.value.refine['value'] = data['refine_order']
      entryDataNew.value['order_type']['value'] = data.order['order_type']
      entryDataNew.value['order_quantity']['value'] = data.order['order_quantity']
      entryDataNew.value['order_delivery_type']['value'] = data.order['order_delivery_type']
      entryDataNew.value['order_delivery_address']['value'] = data.order['order_delivery_address']
      entryDataNew.value['order_payment_method']['value'] = data.order['order_payment_method']


      const propObject = {
        as: null,
        slug: null,
        value: null,
        label: null,
        placeholder: 'Введите значение',
        minLength: null,
        maxLength: null,
        min: null,
        max: null,
        valid: false,
        message: null,
        rules: null,
        options: null,
      }

      // data['rubric_props'].forEach(
        // (item, index) => {
        //   console.log('> index 1', item.title)

        //   entryDataNew.value.props[index] = { ...propObject }
        //   entryDataNew.value.props[index].label = item.title
        //   console.log('> index 2.0',item.label)
        //   console.log('> index 2.1',entryDataNew.value.props[index].label)
        //   console.log('> index 2.2',entryDataNew.value.props[index])
        //   entryDataNew.value.props[index].slug = item['prop_id']

        //   if (
        //     item['type_of'] === 'I' ||
        //     item['type_of'] === 'S' ||
        //     item['type_of'] === 'F'
        //   ) {
        //     entryDataNew.value.props[index].as = 'text'
        //     entryDataNew.value.props[index].value = item.value
        //   } else if (item['type_of'] === 'E') {
        //     entryDataNew.value.props[index].as = 'select'
        //     entryDataNew.value.props[index].value = item.value_enum
        //   } else if (item['type_of'] === 'D') {
        //     entryDataNew.value.props[index].as = 'date'
        //     entryDataNew.value.props[index].value = item.value
        //   }

        //   if (item.required) {
        //     entryDataNew.value.props[index].rules = []
        //     entryDataNew.value.props[index].rules.push('required')
        //   }

        //   if (item.params && item.params.min) {
        //     entryDataNew.value.props[index].min = item.params.min
        //     entryDataNew.value.props[index].minLength = item.params.min.length
        //   }

        //   if (item.params && item.params.max) {
        //     entryDataNew.value.props[index].max = item.params.max
        //     entryDataNew.value.props[index].maxLength = item.params.max.length
        //   }

        // }
      // )
      // console.log('> index 3', entryDataNew.value.props)

      return newData
    }



    /*********************
    * MEASURE END
    *********************/



    /*********************
    * RUBRICS START
    *********************/

    const selectedRubric = ref(null)
    const rubricsList = ref(null)
    const rubricsPropsList = ref(null)
    const rubricsInitTree = ref(null)
    const categoriesArray = ref(null)


    /**
     * Request for all the rubric props.
     * @returns {array} An array of rubrics or an empty array.
     */
    const requestRubricProps = async id => {
      ApiService.setHeader()

      return await ApiService.get(`/rubric/${id}/props`)
        .then(response => {
          // console.log('> requestRubricProps >> Success', response.data)
          return response.data
        })
          .catch(error => {
            console.error('> requestRubricProps >> Error')
            return []
          })
    }


    /**
     * Converting rubric props objects with options and value fields.
     * @returns {array} Array of rubrics.
     */
    const setRubricProps = () => {
      const resultRubrics = []

      // console.log('> rubricsPropsList.value', rubricsPropsList.value)
      // console.log('> entryData.value', entryData.value['rubric_props'])

      rubricsPropsList.value.forEach(
        rubricItem => {
          const newRubric = {
            id: rubricItem.id,
            value: null,
            title: rubricItem.name,
            enums: rubricItem.enums,
            params: rubricItem.params,
            required: rubricItem.required,
            'type_of': rubricItem['type_of'],
          }

          if (entryData.value['rubric_props'].length) {

            entryData.value['rubric_props'].forEach(
              subRubricItem => {
                /*
                  Making cross matching for IDs.
                */
                if (subRubricItem['prop_id'] === rubricItem.id) {
                  newRubric.title = subRubricItem.title
                  newRubric.id = subRubricItem['prop_id']
                  /*
                    For input fields enums could have a null value.
                  */
                  if (rubricItem.enums && rubricItem.enums.length) {
                    newRubric.enums = rubricItem.enums
                  }
                  /*
                    The value for different types of fields
                    could be placed in different variables
                    like value_enum or value.
                  */
                  if (subRubricItem['type_of'] === 'F') {
                    newRubric.value = subRubricItem.value
                  } else if (subRubricItem['type_of'] === 'E') {
                    newRubric.value = subRubricItem['value_enum']
                  } else if (subRubricItem['type_of'] === 'I') {
                    newRubric.value = subRubricItem.value
                  } else if (subRubricItem['type_of'] === 'D') {
                    newRubric.value = subRubricItem.value
                  } else if (subRubricItem['type_of'] === 'S') {
                    newRubric.value = subRubricItem.value
                  }
                  newRubric['type_of'] = subRubricItem['type_of']
                }
              }
            )

            resultRubrics.push(newRubric)

          } else {
            resultRubrics.push(newRubric)
          }
        }
      )

      // console.log('> resultRubrics', resultRubrics)
      return resultRubrics
    }


    /**
     * Converting rubric props objects with options and value fields.
     * @returns {array} Array of rubrics.
     */
    const setNewRubricProps = () => {
      const resultRubrics = []

      // console.log('> rubricsPropsList.value', rubricsPropsList.value)
      // console.log('> entryData.value', entryData.value['rubric_props'])

      rubricsPropsList.value.forEach(
        rubricItem => {
          const newRubric = {
            id: null,
            as: null,
            slug: rubricItem.id,
            value: null,
            label: rubricItem.name,
            placeholder: 'Введите значение',
            minLength: null,
            maxLength: null,
            min: null,
            max: null,
            valid: false,
            message: null,
            rules: null,
            options: rubricItem.enums,
          }

          if (entryData.value['rubric_props'].length) {

            entryData.value['rubric_props'].forEach(
              subRubricItem => {
                /*
                  Making cross matching for IDs.
                */
                if (subRubricItem['id'] === rubricItem.id) {
                  newRubric.label = subRubricItem.title
                  newRubric.id = subRubricItem['id']
                  newRubric.value = subRubricItem.value
                  /*
                    The value for different types of fields
                    could be placed in different variables
                    like value_enum or value.
                  */
                  if (subRubricItem['type_of'] === 'E') {
                    newRubric.value = subRubricItem['value']
                  }

                  if (rubricItem.required) {
                    newRubric.rules = []
                    newRubric.rules.push('required')
                  }

                  if (rubricItem.params && rubricItem.params.min) {
                    newRubric.min = rubricItem.params.min
                    newRubric.minLength = rubricItem.params.min.length
                  }

                  if (rubricItem.params && rubricItem.params.max) {
                    newRubric.max = rubricItem.params.max
                    newRubric.maxLength = rubricItem.params.max.length
                  }

                  if (
                    rubricItem['type_of'] === 'I' ||
                    rubricItem['type_of'] === 'F' ||
                    rubricItem['type_of'] === 'S'
                  ) {
                    newRubric['as'] = 'text'
                  } else if (rubricItem['type_of'] === 'E') {
                    newRubric['as'] = 'select'
                  } else if (rubricItem['type_of'] === 'D') {
                    newRubric['as'] = 'date'
                  }

                  // console.log('> newRubric', newRubric)
                }
              }
            )

            resultRubrics.push(newRubric)

          } else {
            resultRubrics.push(newRubric)
          }
        }
      )

      // console.log('> resultRubrics NEW', resultRubrics)
      return resultRubrics
    }


    /**
     * Makes request for rubrics array.
     * @returns {array} Array of rubrics.
     */
    const rubricsRequest = async () => {
      // console.log('> run rubricsRequest')
      return await store.dispatch(
        Actions.GET_RUBRICS_LIST,
        {
          limit: 10,
        }
      )
    }


    /**
     * Request for all the rubric props.
     * @returns {array} An array of rubrics or an empty array.
     */
    const requestRubricSettings = async id => {
      ApiService.setHeader()

      return await ApiService.get(`/rubric/${id}/settings`)
        .then(response => {
          // console.log('> requestRubricSettings >> Success', response.data)
          return response.data
        })
          .catch(error => {
            console.error('> requestRubricSettings >> Error')
            return []
          })
    }


    /**
     * Updating rubric properties depends on rubric id changes.
     * 1) Makes request to server for props of the rubric.
     * 2) Converts props for editing.
     * @params {number} ID of the rubric.
     */
    const updateRubricProps = async rubricId => {
      rubricsPropsList.value = await requestRubricProps(rubricId)
      entryData.value['rubric_props'] = setRubricProps()
      entryDataNew.value['rubric_props'] = setNewRubricProps()
    }


    /**
     * Updating measure ID on rubric ID changes.
     * 1) Makes request to server for props of the rubric.
     * 2) Updates measure_id.
     * @params {number} ID of the rubric.
     */
    const updateMeasure = async rubricId => {
      if (rubricId) {
        selectedRubric.value = await requestRubricSettings(rubricId)
        if (selectedRubric.value && selectedRubric.value.data && selectedRubric.value.data.measure) {
          entryData.value['measure_id'] = selectedRubric.value.data.measure.id
          entryDataNew.value.measure.value = selectedRubric.value.data.measure.id
        } else {
          // console.log('> 1', selectedRubric.value)
          // entryData.value['measure_id'] = null
        }
      } else {
        entryData.value['measure_id'] = null
        entryDataNew.value.measure.value = null
        selectedRubric.value = null
      }
    }


    /**
     * Watching for the Rubrics input value
     * and changes rubric properties.
     */
    watch(
      () => entryDataNew.value.rubric.value,
      async newValue => {
        await updateRubricProps(newValue)
        await updateMeasure(newValue)
      }
    )


    // watch(
    //   () => entryData.value['address_json'],
    //   async newValue => {
    //     selectAddress(newValue)
    //   }
    // )


    // watch(
    //   () => entryDataNew.value.address.value.json,
    //   async newValue => {
    //     selectAddress(newValue)
    //   }
    // )



    /**
     * Getting rubrics.
     * Making list view.
     * Making tree view.
     * Push the data to the state.
     */
    const getRubrics = async () => {
      let rubrucsList = []
      rubrucsList = await rubricsRequest()
      store.commit(Mutations.SET_RUBRICS_LIST_MUTATION, rubrucsList)
      rubricsInitTree.value = await store.dispatch(Actions.SET_RUBRICS_TREE, rubrucsList)
    }


    /**
     * Settings for a cascader.
     */
    const rubricSettings = {
      value: 'id',
      emitPath: false,
      multiple: false,
      checkStrictly: true,
    }

    /*********************
    * RUBRICS END
    *********************/



    /*********************
    * PRICE START
    *********************/

    const priceAppend = ref(null)
    const priceFormatted = ref(null)

    /**
     * Price formatting.
     */
    const priceFormat = computed({
      get() {
        if (!priceFormatted.value && entryData.value.price) {
          return entryData.value.price
        } else {
          return priceFormatted.value
        }
      },
      set(value) {
        entryData.value.price = +value
        priceFormatted.value = +value
      }
    })

    /*********************
    * PRICE END
    *********************/



    /*********************
    * ORDER OPTIONS START
    *********************/

    /**
     * Order quantity.
     */
    const orderQuantity = ref(null)
    const orderQuantityMeasure = ref(null)

    /*********************
    * ORDER OPTIONS END
    *********************/



    /*********************
    * DATE FIXED UNTIL START
    *********************/

    const dateFixedUntil = computed({
      get() {
        return entryData.value['fixed_until']
      },
      set(value) {
        entryData.value['fixed_until'] = value as string
      }
    })

    /*********************
    * DATE FIXED UNTIL END
    *********************/


    const clientHost = computed(() => {
      return process.env.VUE_APP_HOST
    })


    /*********************
    * COMMON START
    *********************/

    /**
     * Setting the entry data:
     * 1) request to sesrver
     * 2) convert for edit
     */
    const setEntryData = async() => {
      entryData.value = await getEntryOnLoad() as InterfaceEntryAdsData
      convertDataForEdit(entryData.value)
      convertEntryDataNewForEdit(entryData.value)
    }

    /*********************
    * COMMON END
    *********************/



    onMounted(async () => {
      measuresList.value = await getMeasures()
      // console.log('> measuresList.value', measuresList.value)
      if (props.edit) {
        await setEntryData()
      } else {
        entryData.value = await getEntryOnLoad() as InterfaceEntryAdsData
        entryData.value['measure_id'] = measuresList.value[0].id
        entryDataNew.value.measure.value = measuresList.value[0].id
      }
      await getRubrics()
      // validateForm()
    })



    return {
      validationRules,
      propEdit,
      // REFS
      refsArray,
      refsType,
      refsName,
      refsDescription,
      refsAddress,
      refsCountry,
      refsUpload,
      refsOrderVolume,
      refsOrderQuantity,
      refsOrderDelivery,
      refsOrderDeliveryAddress,
      refsOrderPaymentMethod,
      refsPrice,
      isEmptyObject,
      // FORM
      formRef,
      entryData,
      // PRICE
      priceFormat,
      priceAppend,
      resetForm,
      // ADDRESS
      addressString,
      selectAddress,
      changeAddress,
      addressSuggestions,
      countrySuggestions,
      changeDeliveryAddress,
      selectDeliveryAddress,
      // RUBRICS
      selectedRubric,
      rubricsList,
      rubricSettings,
      categoriesArray,
      rubricsInitTree,
      rubricsPropsList,
      // MEASURE
      measuresList,
      priceMeasureId,
      // ORDER OPTIONS
      orderQuantity,
      orderQuantityMeasure,
      // DATE FIXED UNTIL
      dateFixedUntil,
      // OTHER
      clientHost,
      isNumber,
      disabledDateFromFuture,
      // NEW FORM
      saveForm,
      validateForm,
      validateField,
      entryDataNew,
      trimOnlyNumbers,
      trimOnlyNumbers2,
      trimOnlyNumbers3,
      trimMinMax,
      disabledDateFromPast,
      changeRubric,
      // trimPriceFormat,
      checkLastSymbol,
      trimMultipleDots,
      trimDecimals,
      trimDotAtTheEnd,
      trimPriceLength,
    }
  }
})
